@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes tinyFadeIn {
  from {
    opacity: 0;
    transform: translateY(8px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes headingFadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes showBlock {
  0% {
    opacity: 0;
    transform: translate(-50%, -40%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes hideBlock {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
}

@keyframes modalWrapperFadeIn {
  0% {
    background: transparent;
  }

  100% {
    background: rgba(65, 65, 74, 0.54);
  }
}

@keyframes modalWrapperFadeOut {
  0% {
    background: rgba(65, 65, 74, 0.54);
  }

  100% {
    background: transparent;
  }
}

@keyframes cardAnimation {
  0% {
    // opacity: 0;
    transform: scale(0.97);
  }

  100% {
    // opacity: 1;
    transform: none;
  }
}

@keyframes sectionAnimation {
  0% {
    opacity: 0;
    transform: scale(0.94);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes pointerEventsActivate {
  100% {
    pointer-events: all;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 40px, 0);
  }

  100% {
    transform: translateZ(0);
  }
}